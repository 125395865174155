<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Customers ({{count}})</h5>
			</CCol>
	     		 <CCol lg="6">
					   <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i> Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			       
				 <!-- <CButton 
					v-if="config.getPermission('customers').create"
					size="sm" style="margin-right: 10px" 
					color="success" @click="toggleModal()" 
					class="float-lg-right"><i class="fa fa-plus"></i>Add Customer
					</CButton> -->
					<CButton 
					 v-if="config.getPermission('customers').create"
					 size="sm" style="margin-right: 10px" 
					 target="_blank " href="#/data/create_customer" 
					 color="success"  
					 class="float-lg-right">
					 <i class="fa fa-plus"></i> Add Customer</CButton>
					<CButton 
					v-if="config.getPermission('customers').download"
					size="sm" style="margin-right: 10px" 
					color="btn btn-outline-primary" @click="download()" 
					class="float-lg-right"><i class="fa fa-download"></i> Download
					</CButton>
				 </CCol>	
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	    
		      <CCol lg="12" >  
				  <div class="table-responsive-sm table-responsive-md">
			    	<CDataTable
					  :loading="isLoading"
				      :items="dataList.data"
				      :fields="fields"
					 	hover
         				striped
						 outlined
						 
						  
				    >  
					<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}
								</CBadge>
         						 </td>
							</template>
				      	<template #action="{item}">
					        <td style="padding: 5px; width: 100px">
					        	<CDropdown 
					                toggler-text="Select"  
									size="sm"
					                color="info"
					            >
					            	<CDropdownItem v-if="config.getPermission('customers').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem> 
					                <CDropdownItem v-if="config.getPermission('customers').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit </i></CDropdownItem>
					                <CDropdownItem v-if="config.getPermission('customers').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
					            </CDropdown>
					        </td>
				      	</template>
				    </CDataTable> 
				    <CPagination
			          	:active-page.sync="currentPage"
			          	:pages="Math.ceil(dataList.total / 20)"
			          	:activePage="currentPage"
			          	@update:activePage="updatePage"
			        />
				</div>
	      	
	      </CCol>
	    </CRow>
	  
        <CModal title="View Customer" :show.sync="showViewModal"  color="info" size="lg">
        	<div class="p-2">
			<fieldset class="fieldset full-line">
			<legend>Customer Information</legend>	
			
        		<CRow class="mb-2">
	        		<CCol lg="3">
	        			Customer Name:
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600"> {{dataParams.customer_name}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Contact Number:
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600">  {{dataParams.contact_no}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Email Address:
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600">  {{dataParams.email}} </span>
	        		</CCol>
	        	</CRow>
				<CRow class="mb-2">
	        		<CCol lg="3">
	        			Depot:
	        		</CCol>
	        		<CCol lg="9">
	        		<span style="font-weight:600"> 	{{dataParams.depot == null ? "N/A" : dataParams.depot.setting_name}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Address 1:
	        		</CCol>
	        		<CCol lg="9">
	        		<span style="font-weight:600"> 	{{dataParams.address_a ? dataParams.address_a : "-"}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Address 2:
	        		</CCol>
	        		<CCol lg="9">
	        		<span style="font-weight:600"> 	{{dataParams.address_b ? dataParams.address_b : "-"}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Contact Person:
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600">  {{dataParams.contact_person ? dataParams.contact_person : "-"}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			TIN:
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600">  {{dataParams.tin ? dataParams.tin : "-"}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Account Code:
	        		</CCol>
	        		<CCol lg="9">
	        		<span style="font-weight:600"> 	{{dataParams.account_code  ? dataParams.account_code : "-"}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Rate Type:
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600">  {{dataParams.rate_type ? dataParams.rate_type : "-"}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Status:
	        		</CCol>
	        		<CCol lg="9" class="text-uppercase">
	        		<span style="font-weight:600"> 	{{dataParams.status}} </span>
	        		</CCol>
	        	</CRow> 
			</fieldset>
        	</div>
        	<!-- <div slot="footer" class="w-100">
            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="showViewModal = false">
                    Close
                </CButton> 
                
            </div> -->
        	<div slot="footer" class="w-100"></div>
        </CModal>
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import '../../style.css'
export default {
	mounted(){ 
		this.getData();
		this.getDepotList()
	
	},
	data(){
		return{
			title:'',
			config,
			isLoading: false,
			formModal: false,
			showViewModal: false,
			dataList: [],
			count: 0,
			depotName: "",
			depot: null,
			dataParams: {
				customer_name: "",
				contact_no: "",
				email: "",
				address_a: "",
				address_b: "",
				contact_person: "",
				tin: "",
				account_code: "",
				rate_type: "",
				status: "active",
				depot_id: ""
			},
			fields: [
				{
					key: 'customer_name', 
					label: 'Name'
				},
				{
					key: 'email', 
					label: 'Email'
				},
				{
					key: 'address_a', 
					label: 'Address'
				},
				{
					key: 'contact_no', 
					label: 'Contact No.'
				},
				{
					key: 'depot', 
					label: 'Depot'
				},
				// {
				// 	key: 'account_code', 
				// 	label: 'Account Code'
				// },
				{
					key: 'status', 
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPage: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				contact_no: "",
				email: "",
				account_code: "",
				address_a: "",
				customer_name: "",
			},
			depotList: {
				data:[]
			}
		}
	},
	name: 'Tables',
	
	components: {vSelect, Search},
	methods: {
		depotChanged(ev) {
			this.depot = ev;
			this.dataParams.depot_id = ev ? ev.id : "";
		},
		download() {
			this.$showLoading(true)
			axios.get(config.api_path+'/customer/report/list',{
				params: {
					contact_no : this.filter.contact_no,
					email : this.filter.email,
					account_code : this.filter.account_code,
					address_a : this.filter.address_a,
					customer_name : this.filter.customer_name
				}
			}).then(response => {
				this.$showLoading(false)
				window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
	
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
			}).catch(err => {
				this.$showLoading(false)
			})
		},
		modalClosed() {
			alert('asd')
		},
		getBadge (status) {
    	return status === 'active' ? 'success'
             : status === 'inactive' ? 'danger' : 'primary'
    }, 
		updatePage(data){
			this.currentPage = data;
			this.getData();
			
		}, 

	    toggleModal(){
			this.depot = null;
	    	this.formModal = !this.formModal;
			this.title = "Add Customer";
	    },

	    submit(){
	    	var ax = {};
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/customer/"+this.selectedRow.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/customer", this.dataParams)
	    	}
			this.$showLoading(true)
	    	ax.then(response => {
				const { status } = response.data;
				
				if(status === 'duplicate') {
					Swal.fire({
					title: 'Customer name already exists.',
					text,
					icon: 'error', 
				})
				this.$showLoading(true)
					return;
				}

	    		var text = 'Customer successfully added!';
	    		if(this.editMode){
	    			text = 'Customer successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					customer_name: "",
					contact_no: "",
					email: "",
					address_a: "",
					address_b: "",
					contact_person: "",
					tin: "",
					account_code: "",
					rate_type: "",
					status: "active",
					depot_id : ""
				}
				this.depot = null;
	    		this.getData();
				this.$showLoading(false)
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    },

	    search(event){
	    	this.filter = event; 
	    	this.getData();
	    },

	    getData(){

	    	this.isLoading=true;
	    	axios.get(config.api_path+'/customer?contact_no='+this.filter.contact_no+'&email='+this.filter.email+'&account_code='+this.filter.account_code+'&address_a='+this.filter.address_a+'&customer_name='+this.filter.customer_name+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{
					value.account_code = value.account_code ? value.account_code ?? '' : '';
					value.contact_no = value.contact_no ? value.contact_no ?? '' : '';
					value.address_a = value.address_a ? value.address_a ?? '' : '';
					value.depot = value.depot ? value.depot.setting_name ?? '' : '';
			
	    			return value;
	    		}); 

				this.count= response.data.total
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },
		updateModal(item){
			
			window.open('/#/data/update_customer/'+item.id, '_blank');
			

	    },
	    // updateModal(item){
		// 	this.title = "Update Customer";
	    // 	this.selectedRow = item;
	    // 	this.dataParams = {...item};
		// 	this.depot = item.depot;
	    // 	this.formModal = true;
	    // 	this.editMode = true;
	    // },

	    // viewModal(item){

	    // 	this.selectedRow = item;
	    // 	this.dataParams = item;
	    // 	this.showViewModal = true; 

	    // },
		viewModal(item){

		window.open('/#/data/view_customer/'+item.id, '_blank');


		},

	    deleteRow(item){
	    	Swal.fire({
				title: 'Are you sure you want to delete this customer?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/customer/'+item.id)
			    	.then(response => {
						const {status, has_booking} = response.data;
						this.$showLoading(false)
						if(has_booking == 1) {
							Swal.fire({
								title: 'Delete failed!',
								text: "A customer that was already assigned to a booking cannot be deleted. Please contact a support.",
								icon: 'error', 
							})
						}
						
						// else if(has_equipment == 1) {
						// 	Swal.fire({
						// 		title: 'Delete failed!',
						// 		text: "A customer that was already assigned to equipment cannot be deleted. Please contact a support.",
						// 		icon: 'error', 
						// 	})
						// }
						
						else {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "Customer successfully deleted",
							icon: 'success', 
						})
					}
			    	}) 
				}
			})  
	    },

	    updateStatus(data){
	    	this.dataParams.status = data;
	    },
		getDepotList(){

          axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'depot'
            }
          })
          .then(response => {
            this.depotList = response.data;
          })

        },
 	}
}
</script>
